.login_form_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}

.login_form_input {
    /* width: 30rem; */
    height: 8rem ;
    text-align: center;
    font-size: 2.5rem !important;
}

