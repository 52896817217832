
.new_client {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    justify-content: center;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(20px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    gap: 20px;
    margin-bottom: 30px;
}

.new-user-form-container {
    display: flex;
    justify-content: space-evenly;
}

.new-user-columns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100% - 60px);
    gap: 25px;
}

.new_client_image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

#test_rtl{
    text-align:right !important;
}

.additional_info_text {
    
    
    text-align: right;
    gap: 10px;
}

.input_label {
    width: 98%;
    text-align: right;
}

.section_header {
    margin-left: 10px;
    font-weight: 600;
}

.user_notes {
    height: 120px;
}


  
@media screen and (max-width: 991.98px) {
    /* Your styles for screens with a width less than 576 pixels go here */
    .new_client_image{
        width: 170px;
        height: 170px;
    }

    .icons-container {
        width: 100%;
        flex-wrap: wrap;

    }

}

.date_year, .date_month, .date_day{
    padding-left: unset;
    padding-right: unset;
    /* margin: 0 auto; */
    text-align: center;
}
.date_month{
    width: 2.3vw;
}
.date_day{
    width: 2.3vw;
}