.pdf-upload-container {
    min-width: 200px;
    max-height: 400px;
}

.pdf-form-container {

}

.pdf-preview-container {
    display: flex;
    justify-content: center;
    overflow: auto;
}

.react-pdf__Page {
    max-height: 200px;
}