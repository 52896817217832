@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.section-end {
    text-align: center;
}

/* Optional: To make the table look more like the uploaded image */
table {
  
  border-collapse: collapse;
}

th, td {
  border: 1px solid #a3a3a3; /* Lighter border for all cells */
  padding: 0.15rem !important;
  text-align: left; /* Align text to the left */
  vertical-align: middle;
  
}

.eye-indicator {
  font-size: 1rem;
}
.balance_row {
  background-color: rgb(216, 216, 216) !important;
}

.highlight {

  border-color: red;
}

.va-handler {
  display: flex;
  justify-content: center;
  gap: 5px;
  vertical-align: middle !important;
  font-size: 1rem;
  align-items: center;
}

.va-handler input{
  width: 80%;
  font-weight: 450;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.double-row-center{
  vertical-align: middle;
}

.clone-button{
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 10px;
  border-right: 1px solid #a3a3a3;
  border-bottom: 1px solid #a3a3a3;
  border-top: 1px solid #a3a3a3;
  margin-bottom: 16px;
}

.typeahead div input {
  background-color: rgb(216, 216, 216);
}

.form-control {
  /* font-size: 1.16rem;
  padding: 2px 0;
  text-align: center; */
  font-weight: 450;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  padding-left: unset;
  padding-right: unset;
  text-align: center;
  font-size: 0.95rem;
}


@media (max-width: 480px){
  .va-handler input{
    min-width: 100px !important;
  }

  table input {
    min-width: 100px !important;
  }

  .form-control {
    min-width: 100px;
  }
}