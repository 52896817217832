.glasses_container {
    /* display: flex; */
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    gap: 20px;

}

.glasses_section {
    display: flex;
    /* flex-direction: row; */

    /* transform: translateY(20px);
    transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

.table-note-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-note {

    height: 90%;
}

.glasses-order-title {
    width: 100%;
    border-bottom: 1px solid #a3a3a3;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.glasses-order .form-label {
    width: 30%;
    font-size: 1rem;
}

.glasses-order .form-control {
    width: 200px !important;
}

.glasses-order.form-control, .typeahead {
    
    font-size: 1rem; 
}

.glasses-order .form-group {
    margin-bottom: 16px; 
}

.additional-glasses-title {
    padding: 10px;
    display: flex;
    align-items: center;
}

.additional-glasses-title label{
    padding-right: 10px;
    
}

.date-of-delivery {
    width: 160px !important;
}



@media screen and (max-width: 991.98px) {
    .glasses_section {
        display: block;
        /* flex-direction: row; */
    
        /* transform: translateY(20px);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        gap: 20px; */
    }
}