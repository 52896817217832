.disabled-div {
    pointer-events: none;
    opacity: 0.5;
}

.refraction_container {
    /* display: flex; */
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.refrection-button-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.card_header_section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.notes-container {
    border: 1px solid black
}

.close-examine-card {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
}

.open-examine-card {
  max-height: 8000px;
  transition: max-height 3s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.display-hidden-on-close{
    display: none;
}
.checker-name {
    width: 40%;
}
.ref-note {
    margin: auto;
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 991.98px) {
    .refraction_section {
        display: block;
        /* flex-direction: row; */
    
        /* transform: translateY(20px);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        gap: 20px; */
    }
    .checker-name {
        width: 100%;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  /* Simple loading animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  .refraction-note-responsive {
    display: flex;
    flex-direction: row;
    
    }

  @media (max-width: 480px){
    .refraction-note-responsive {
        flex-direction: column !important;
        
    }

    .refraction-note-responsive :nth-child(1){
        
        
    }
  }


