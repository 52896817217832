.login_screen {
    display: flex;
    /* 
    flex-direction: row;
    height: 100vh; */
    
}

.login_screen .row{
    flex-grow: 1;
}

.login_cover {
    /* width: 65%; */
    background-image: url('../../assets/images/glasses_cover.jpg');
    background-size: cover;
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width: 35%; */
    background-color: rgba(191,229,240,255);
    
}

.login_credits {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 10px;
    bottom: 10px;
    gap: 10px;
}

.login_credits p {
    margin: 0;
    font-size: 1.2rem;
}

/* @media (max-width: 480px) {
    .login_screen {
        flex-direction: column;
    }
} */
/* .login-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    gap: 10px;
}
.login-container form div {
    margin: auto;
}

.login-container input {
    width: 40%;
    margin: auto;
    border-radius: 12px;
    height: 25px;
}

.login-container button {
    width: 30%;
    margin: auto;
    border-radius: 8px;
}

.login-container h5 a {
    color: red;
} */

@media screen and (max-width: 767.98px) {
    /* Your styles for screens with a width less than 576 pixels go here */
    .login_cover{
        display: none;
    }
  }