.expand_icon{
    display: flex;
    position: fixed;
    left: 0;
    top:300px;
    width: 25px;
    height: 45px;
    background-color: lightblue;
    opacity: 85%;
    border-radius: 0px 15px 15px 0px;
    /* justify-content: center; */
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar_close {
    transform: translateX(0);
    padding-right:8px ;
    transition: transform 0.2s ease-out;
}
.sidebar_open {
    position: fixed;
    /* transform: translateX(calc(calc(17% - 15px) + 25px)); */
    left: calc(17% - 15px) ;
    justify-content: center;
    transition: transform 0.2s ease-out;
}


.expand_icon:hover.sidebar_close{
    width:40px;
    transition: width 0.1s ease-in-out;
}


@media(max-width: 1850.99px) { 
    .sidebar_open {
      left: 300px;
    }  
}


/* 
  .expand_icon{
    display: flex;
    position: fixed;
    left: 0;
    top:300px;
    width: 25px;
    height: 45px;
    background-color: lightblue;
    opacity: 85%;
    border-radius: 0px 15px 15px 0px;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
}

.expand_icon.sidebar_close {
    transform: translateX(0);
    transition: transform 1s ease-out;
}

.expand_icon.sidebar_open {
    transform: translateX(300px);
    transition: transform 0.1s ease-out;
}


.expand_icon:hover {
    width:40px;
    transition: width 0.1s ease-in-out;
}

@media(min-width: 1199.99px) {
    .expand_icon {
      display: none;
    }  
  } */