.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.title-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}


.title-container  .title {
    display: flex;
    width: 33%;
    
}

.title-container  .inspection {
    display: flex;
    justify-content: center;
    width: 34%;
}

.title-container  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 33%;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

@media (max-width: 480px){
    .title-container {
        flex-direction: column;
      
    }

    .title-container  .buttons {
        justify-content: center;
    }

        
    .title-container  .title {
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

}