.disease_modal_container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding-bottom: 10px;
}

.new-disease {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}