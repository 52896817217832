
.profile-card {
  display: flex;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  flex-direction: column;
  gap: 25px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}
  
.profile-image {
  max-width: 100%;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid rgba(56,69,75,0.3);
  object-fit: contain !important;
}

.profile-fill-fit {
  font-size: 5em; 
}
  
.profile-card-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profile-card-mid{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  direction: rtl;
}

  
.profile-card-mid > div {
  display: flex;
  justify-content: space-between;
  direction: rtl;
}

.profile-card-bot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.profile-card-bot div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 2px;
  
}

.profile-card-bot div label{
  cursor: pointer;
  width: 80%;
  margin:  auto;
}

.bg-color-1 {
 
  background-color: #e57373;

}

.bg-color-2 {
  background-color: #64b5f6; /* Example color */
}

.bg-color-3 {
  background-color: #81c784; /* Example color */
}

.bg-color-4 {
  background-color: #ffd54f; /* Example color */
}

.more_options{
  cursor: pointer;
  position: absolute;
  left: 5px;
  text-align: right;
}
.profile-card-icons{
  /* display: flex; */
  height:55px;
  width:55px;
  /* background-color: lightblue; */
  border-radius: 5px;
  /* align-items: center; */
}
@media screen and (max-width: 1300px){}

