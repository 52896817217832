
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.App {
  display: flex;
  flex-direction: column;
  /* height: 100vh; Full viewport height */
  text-align: center;
  background-color: rgba(223,223,223,255);
  overflow-x: hidden;
  font-size: 20px;
  min-height: 100vh;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  
}


.header-footer {
  flex-shrink: 0;
  position: -webkit-sticky;
  z-index: 100;
}

.main-content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}


.main_menu{
  margin-top: 50px !important;
  margin-bottom: 20px !important;


}

.loya_logo{
  padding-top: 25px;
  object-fit: contain;
  width: auto;
  height: auto;
}



@media(max-width: 971.98px) {
  .main_menu {
      margin-left: unset;
      overflow-y: scroll;
  }

}

@media print {
  .no-print {
    display: none !important;
  }

  body {
    font-size: 12pt;
  }

  .print-friendly {
    width: 100%;
    display: block;
  }

  /* Ensure tables are fully visible */
  table {
    display: block !important;
    min-width: 100%;
    page-break-inside: auto;
    
  }

  table tr {
    
  }

  table td  {

  }

  table th {
   
  }

  table thead {
    width: 100%;
    font-size: 12px;
  }

  [class^="col-"],.row , .table-print , tbody , .refraction-note-responsive{
    gap: 20px;
    display: auto !important;
    width: 100% !important;
  }


  *{
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none;
    background-color: #fff;
  }

  .pagebreak { page-break-before: always; } /* page-break-after works, as well */


  .main_menu .glasses-order-title .glasses-order {
    margin-top: 0 !important;
  }

  .expand_icon.sidebar_close{
    display: none;
  }

  .refraction_container  {
    box-shadow: none !important;
  }

  .lenses_container {
    box-shadow: none !important;
  }
  .examines-container {
    page-break-before: avoid;
  }
  .glasses_container {
   
    box-shadow: none !important;
  }

  .main_menu {
    margin-top: 0px !important;
  }
  table .table_input .va-handler{
    display: block !important;
    width: 40px !important;
    height: 30px !important;
    overflow: hidden !important;
    
    color: red !important;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .form-control {
    font-size: 0.7rem  !important;
    padding: 2px 0;
    text-align: center;
  }

  .va-prefix {
    font-size: 10px;
  } 

  .eye-indicator {
    font-size: 10px;
  }

  .lenses-override {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 25px;
  }

  .lenses-form {
    display: flex !important;
    flex-direction: row !important;
  }
  

  .lenses-form .form-double-row input {
    width: 70px !important;
    height: 25px !important;
  }

  .lenses-form .form-single-row input{
    width: 150px !important;
    height: 25px !important;
  }

  .lenses-form * label {
    font-size: 12px;
  }


  .lenses-form .col {
    width: 30% !important;
  }

  .balance_row {
    color: rgb(216, 216, 216);
  }
  
  .print-large {
    width: 75px;
    color: red !important;
  }

  .print-order-1 { order: 1; }
    .print-order-2 { display: block !important; order: 2; }
    .print-order-3 { order: 3; }
    .print-order-4 { order: 4; }
    .print-order-5 { order: 5; }
    .print-order-6 { order: 6; }

}