.cropper-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    height: 400px;
}

.buttons-container {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: center;
    flex-direction: row;
    z-index: 1;

}