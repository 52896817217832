.upload_container {
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    gap: 20px;
    
}


.upload_section {
    display: flex;
}

.pdf-container {
    border: 1px solid red;
}

.patient-upload-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
   
}