.header_container {
    
    display: flex;
}

.header_section {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    /* transform: translateY(20px); */
    margin-top: 20px;
    /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
    /* gap: 20px; */
    font-size: 18px !important;

}

.header_icons {
    justify-content: center;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
}

/* .header_right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;

} */

.header_right div {
    display: flex;
    width: 25%;
}

.header_field_row{
    display: flex;
    flex-direction: row;
    /* text-align: right; */

}

.header_field_column{
    flex-direction: column;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.patient_header{
    display: flex;
    flex-direction: row;
}
.patient_header_avatar{
    
    display: flex;
    justify-content: center;
    
}

.header-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid blue;
}

.medical_condition {
    align-items: center;
    border: 1px solid black;
    gap: 5px;
    justify-content: center;
    background-color: rgb(255, 187, 0);
    border-radius: 5px;
    width: 90%;
}

.no_medical_condition {
    align-items: center;
    border: 1px solid black;
    gap: 5px;
    justify-content: center;
    background-color: rgb(33, 71, 196);
    border-radius: 5px;
    width: 90%;
    color: white;
}

.header_card_icons{
    display: flex;
    height:40px;
    width:40px;
    background-color: lightblue;
    border-radius: 5px;
    align-items: center;
}

.header_icons {
    align-items: center;
}

@media screen and (max-width: 991.98px) {
    /* Your styles for screens with a width less than 576 pixels go here */
    .header_icons{
        display: flex;
        justify-content: space-evenly;
        margin-top: 5px;
    }
    .header_field_row  {
        justify-content: center !important; 
    }
    .header_field_column{
        align-items: center !important;
        height: auto;
        border: none;
        
    }

  }

@media screen and (max-width: 767.98px) {
    /* Your styles for screens with a width less than 576 pixels go here */
  }