.client_container {
    display: flex;
    height: calc(100% - 100px);
    /* flex-direction: row; */

    /* gap: 20px; */
    /* justify-content: center; */
    /* flex-wrap: wrap; */

}

.client_right {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(20px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    gap: 20px;
}

.client_right_top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.client_left {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    transform: translateY(20px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client_header {
    display: flex;
    align-items: center;
    gap: 20px;
}


.client_search_bar{
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
}

.client_table_icon{
    border-radius: 50%;
    padding: 5px;
}

@media screen and (max-width: 991.98px) {
    .client_left{
        display: none;
        /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
    }

    .client_right{
        margin-right: 20px;
    }
}

@media (max-width: 480px){
    .client_search_bar{
        gap: 0px;
        height: 80px;
    }
    .new-client-button {
        height: 80px !important;
    }
}