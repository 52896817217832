.sidebar-comp {
    /* flex: 0 0 250px; */
    /* width: 20%;  */
    background-color: rgba(224,247,255,255);
    overflow-x: hidden; 
    padding-top: 20px; 
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
    z-index: 5;
    position: fixed;
    padding-top: 55px;
  }

 
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar_container {
  height: 100%;
  /* display: flex; */
  /* position: absolute; */
  left:0;
  text-align: right;
  width: calc(17% - 15px);
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0.2s ease-out;
  position: fixed;
  min-width: 300px;
}
.sidebar_container > .left {
  max-width: 300px;
}
.sidebar_container > .right {
  position: relative;
  flex-grow: 1;
  background-color: #f5d500;
  color: #d50a1f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5em;
  font-weight: bold;
}
/* .menu-toggle */
.menu input[type=radio]
 {
  display: none;
}
.menu .collapsed-menu {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: max-height .1s ease-out,
              opacity 0.1s ease-in;
}
.menu > li > label,
.collapsed-menu li {
  display: block;
  cursor: pointer;
  padding: 0.7em;
}
.collapsed-menu li {
  padding-left: 2em;
}
.menu > li > label:hover,
.collapsed-menu li:hover {
  background-color: #d9d9d9; 
}
.menu input[type=radio]:checked ~ .collapsed-menu {
  max-height: 300px;
  opacity: 1;
  visibility: visible;
  transition: max-height .35s ease-out,
              opacity 0.1s ease-in 0.2s;
}

.sidebar_logo{
  display: flex;
  justify-content: center;
}

.patient_set{
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
}
  
.paitent_revoked {
    opacity: 0;
    pointer-events:none;
    transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
}

/* @media(max-width: 1199.98px) {
  .sidebar_container {
    overflow: hidden;
    transform: translateX(-300px);
    transition: transform 0.2s ease-out;
    position: fixed;
    min-width: 300px;
  } */

  /* .sidebar_container > .left {
    min-width: 300px;
  } */
/* 
  .menu-toggle {
    display: block;
    position: absolute;
    cursor: pointer;
    left: 20px;
    top: 20px;
  } */
  /* .menu-toggle div {
    width: 35px;
    height: 5px;
    background-color: #555;
    margin: 6px 0;
  } */
/* } */

.assafmedia_logo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 80px;
}



/* @media(min-width: 1199.99px) {
  .sidebar_container {
    transform: translateX(0) !important;
    transition: transform 0.2s ease-out;
  }

} */