.hide-div {
    display: none;
}

.date-of-delivery-label {
    width: 100% !important;
}

.glasses-order-select {
    text-align: right;
    width: 200px; 
    font-weight: 450;
    font-size: 1rem;
    height: 40px;
}