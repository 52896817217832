.examines-container {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100dvh; */
    overflow-y: hidden;

}

.back-to-top {
    position: fixed;
    bottom: 20px; /* Distance from the bottom of the viewport */
    right: 30px; /* Distance from the right of the viewport */
    z-index: 101; /* Ensures the button is on top of other elements */
    border: none; /* Removes the default border */
    outline: none; /* Removes the outline */
    background-color: #ddd; /* Background color */
    color: black; /* Text color */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
    padding: 15px; /* Padding inside the button */
    border-radius: 10px; /* Rounded corners */
  }
  
  .back-to-top:hover {
    background-color: #ccc; /* Darker shade on hover */
  }

/* .examines-container:hover {
    overflow-y: scroll;
}
 */
