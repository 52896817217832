.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    position: static;
    bottom: 0px;
}

.footer-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
}

.footer label {
    margin-top: 15px;
    font-size: 12px;
}

.footer img {
    width: 100px;
    object-fit: contain;  /* This property ensures the image covers the div without distorting aspect ratio */
  }