.hovered-row {
    background-color: aqua;
}

.client_search_bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table_container{
    margin-top: 15px !important;
    user-select: none;
}

.new_client_button{
    background-color: #5fb3d0;
    border-color: #5fb3d0;
}