.main_menu {
    margin: 0 auto;
    overflow-y: hidden;
    /* padding-left: 17%; */
}


@media(max-width: 1199.98px) {
    .main_menu {
        margin-left: unset;
        padding-left: 2%;
    }
    /* .main_menu:hover {
        overflow-y: unset;
    } */
  }