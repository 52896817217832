.lenses_container {
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    

}

.card_header_section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}


.lenses_section {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(20px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    gap: 20px;
}


.lenses-form {
    display: flex;
    flex-direction: row;
}

.lenses-form .form-double-row{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.lenses-form .form-double-row-item {
    margin: 5px 0px 5px 5px;
    width: 100px;
}

.lenses-form .form-double-row label{
    width: 40%;
}

.lenses-form .form-single-row {
    display: flex;
}

.lenses-form .form-single-row input{
    margin: 10px 0 10px 0;
    width: 200px;
}

.lenses-form .form-single-row label{
    width: 40%;
}

.lenses-form .form_control {
    font-size: unset;
}

@media (max-width: 900px){
    .lenses-form  {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
    }

    .refraction-note-responsive :nth-child(1){
        
        
    }
  }