.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(56,69,75,255) !important;
    padding: 0px;
    margin: 0px;
    position: fixed;
}

.header_father{
    padding: unset !important;
}

.header > .user_info{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 5px;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.user_info > .user_controlers{
    display: flex;
    flex-direction: column;
    font-size: 13px !important;
    padding: 5px;
    align-items: center;
    color: white;
}

nav{
    display: flex;
    align-items: center;
    background: #AB47BC;
    height: 60px;
    position: relative;
    border-bottom: 1px solid #495057;
}

.user-helper {
    color: white;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

.user-helper-columns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}

.header-icons{
    display: flex; 
    flex-direction: row-reverse;
    gap: 15px;

    height: 100%;
    padding-left: 5px;
    font-size: 15px
}
/* 
.icon-text {
    font-size: 14px;
} */


.sub-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.sub-icons label {
    color: white;

}

.hide-logo {
    display: none;
}
.icon {
    scale: .7;
}
.sub-icons:hover{
    cursor: pointer;
    opacity: .7;
    scale: 1.1;
}

.sub-icons label:hover {
    cursor: pointer;
}

.logo{
    flex: 1;
    margin-left: 50px;
    color: #eee;
    font-size: 20px;
    font-family: monospace
}

.profile_pic_icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-color: white;
    object-fit: fill;
    cursor: pointer;
    z-index: 6;
}

.paitent_revoked {
    opacity: 0;
    pointer-events:none;
    transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
}

.patient_set{
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
  }

  @media (max-width: 1400px){
    .user-helper {
        color: white;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        font-size: 16px;
        width: 40%;
        
    }
  }

  @media (max-width: 1200px){
    .user-helper {
        color: white;
        display: flex;
        justify-content: space-evenly !important;
        text-align: center;
        margin-left: 150px;
        font-size: 14px;
        width: 35%;
        
    }
  }

  @media (max-width: 940px){
    .user-helper {
        color: white;
        display: flex;
        justify-content: space-evenly !important;
        text-align: center;
        font-size: 14px;
        margin-left: 0px !important;
        width: 50%;
    }
  }


  @media (max-width: 991px){
    .header-icons {
        padding-left: 0px !important;
    }
    .user_info {
        padding-right: 0px !important;
    }
  }
/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap'); */
/* *{} */