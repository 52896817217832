.preview-container {
    position: relative;
    margin: 10px 5px 10px 5px;
    width: 18%;
    background-color: rgba(0 , 83 , 113 , 0.9); /* Dark background color */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for elevation */
    padding: 20px; /* Optional: for internal spacing */
    border-radius: 10px; /* Optional: for rounded corners */
    color: white; /* Optional: for text color contrast */
}

.preview-settings {
    position: absolute;
    top: 10px;
    right: 10px; 
    cursor: pointer;
  
}

.preview-content {
    width: 100%;
}

.preview-title {
    width: 100%;
    word-wrap: break-word;
}

.preview-render {
    display: flex;
    justify-content: center;
    width: 100%;
}

.preview-note {
    width: 100%;
    word-wrap: break-word;
    background-color: rgba(220 , 220 ,220 ,0.3);
    border-radius: 6px;
    padding: 5px;
    max-height: 50px;
    overflow: auto;
}

@media (max-width: 480px){
    .preview-container {
        width: 100%;
        margin: 10px 0 10px 0;
    }
}