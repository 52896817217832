.custom-button-container {
    position: relative;
    /* margin-top: 5%; */
    /* margin-bottom: 2%; */
}

.custom-button {
   
    position: relative;
    font-size: larger;
    color: rgb(255, 255, 255);
    background-color: none;
    border: none !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out
}

.custom-button-content {
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
}
.custom-button-content label {
    cursor: pointer;
}
.custom-button:hover {
    transform: scale(1.1);
}

@media (max-width: 480px) {
    .custom-button-container {
        display: flex;
        justify-content: center;
    }

    .custom-button {
        width: 100%;
        font-size: medium;
    }
}