.patient-info {
    display: none;
}

/* Display .patient-info only in print */
@media print {
    .patient-info {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-size: 12px;
        gap: 10px;
        text-align: center;
        border: 2px solid #a3a3a3;
    }

    .patient-info > * {
        flex-basis: calc(20% - 20px); /* Initially set for three items per row */
    }

    .patient-info > :nth-child(n+5) {
        flex-basis: calc(20% - 20px); /* Adjust from the fourth item onwards for four items per row */
    }
}